export const ratio = {
    props: {
        ratio: {
            type: [String, Number, Boolean],
            required: false,
            default: false
        },
        'mobile-ratio': {
            type: [String, Boolean],
            required: false,
            default: false
        },
        breakpoint: {
            type: [String, Number],
            required: false,
            default: 960
        }
    },
    computed: {
        ratio_classes() {
            return {
                'sf-r': this.local_ratio && this.local_ratio.length > 0,
                'sf-r-1-1': this.local_ratio === '1/1' || this.local_ratio === '1',
                'sf-r-16-9': this.local_ratio === '16/9',
                'sf-r-2-1': this.local_ratio === '2/1',
                'sf-r-2-3': this.local_ratio === '2/3',
                'sf-r-21-4': this.local_ratio === '21/4',
                'sf-r-21-9': this.local_ratio === '21/9',
                'sf-r-3-4': this.local_ratio === '3/4',
                'sf-r-4-1': this.local_ratio === '4/1',
                'sf-r-4-3': this.local_ratio === '4/3',
                'sf-r-4-5': this.local_ratio === '4/5' || this.local_ratio === 'facebook',
                'sf-r-9-16': this.local_ratio === '9/16',
                'sf-r-reel': this.local_ratio === 'reel'
            }
        }
    },
    data() {
        return {
            local_ratio: this.ratio
        }
    },
    watch: {
        ratio(newVal) {
            this.local_ratio = newVal
        }
    }
}
