<template>
    <div class="services-monkee-order--view">
        <section style="padding: 2rem;">
            <sf-layout>
                <div>
                    <h6>Monkee ordine</h6>
                </div>

                <!-- <div v-if="!is_logged">
                    <div>
                        <label
                        for="auth_pass"
                        style="margin-right: 0.5rem;">Agent pass</label>

                        <input
                        v-model="password"
                        type="text"
                        name="auth_pass"
                        id="auth_pass">
                    </div>
                    <button @click.prevent="login">Login</button>
                </div> -->

                <!-- ------------------------------------------------------------------------------------------------------------------------------------------------
                ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                <div>
                    <div>
                        <label
                        for="auth_pass"
                        style="margin-right: 0.5rem;">Nome dominio</label>

                        <input
                        v-model="model.domain_name"
                        type="text"
                        name="auth_pass"
                        id="auth_pass">
                    </div>

                    <div>
                        <label
                        for="business_name"
                        style="margin-right: 0.5rem;">Ragione sociale</label>

                        <input
                        v-model="model.business_name"
                        type="text"
                        name="business_name"
                        id="business_name">
                    </div>

                    <div>
                        <label
                        for="piva"
                        style="margin-right: 0.5rem;">P.IVA</label>

                        <input
                        v-model="model.piva"
                        type="text"
                        name="piva"
                        id="piva">
                    </div>

                    <div>
                        <label
                        for="fiscalcode"
                        style="margin-right: 0.5rem;">Codice fiscale</label>

                        <input
                        v-model="model.fiscalcode"
                        type="text"
                        name="fiscalcode"
                        id="fiscalcode">
                    </div>

                    <div>
                        <label
                        for="business_address"
                        style="margin-right: 0.5rem;">Sede legale</label>

                        <input
                        v-model="model.business_address"
                        type="text"
                        name="business_address"
                        id="business_address">
                    </div>

                    <div>
                        <label
                        for="business_phone"
                        style="margin-right: 0.5rem;">Nr. telefono</label>

                        <input
                        v-model="model.business_phone"
                        type="text"
                        name="business_phone"
                        id="business_phone">
                    </div>

                    <div>
                        <label
                        for="dati_rappr_legale"
                        style="margin-right: 0.5rem;">Dati rappresentate legale</label>

                        <textarea
                        v-model="model.dati_rappr_legale"
                        rows="10"
                        cols="50"
                        type="text"
                        name="dati_rappr_legale"
                        id="dati_rappr_legale" />
                    </div>
                    <div>
                        <small>nome, cognome, luogo di nascita, CAP del luogo di nascita, data di nascita, indirizzo di residenza, codice fiscale, num telefono, email personale</small>
                    </div>

                    <!-- ------------------------------------------------------------------------------------------------------------------------------------------------
                    ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                    <div>
                        <label
                        for="agent_pass"
                        style="margin-right: 0.5rem;">Agent pass</label>

                        <input
                        v-model="model.agent_pass"
                        type="text"
                        name="agent_pass"
                        id="agent_pass">
                    </div>

                    <div>
                        <button @click.prevent="submit">Invia ordine</button>
                    </div>

                    <div style="margin-top: 2rem;">
                        <div
                        v-if="error"
                        style="color: red;">
                            {{ error }}
                        </div>

                        <div
                        v-if="is_completed"
                        style="color: green;">
                            Ordine inviato con successo!
                        </div>
                    </div>
                </div>
            </sf-layout>
        </section>
    </div>
</template>

<script>
// 3#6dkHdF

export default {
    name: 'services_monkee_order',
    data () {
        return {
            is_logged: false,
            is_completed: false,
            error: null,
            model: {
                agent_pass: null,
                domain_name: null,
                business_name: null,
                piva: null,
                fiscalcode: null,
                business_phone: null,
                business_address: null,
                dati_rappr_legale: null
            }
        }
    },
    methods: {
        // login () {
        //     try {
        //         this.is_logged = true
        //     } catch (err) {
        //     }
        // },
        async submit () {
            this.error = null
            this.is_completed = false

            try {
                await this.$api.sendMailMonkeeOrder({
                    from: 'devops@bxsdigital.com',
                    to: 'simon3fusco@gmail.com',
                    subject: 'Simone Fusco Website | Monkee commessa',
                    message: JSON.stringify(this.model)
                })

                this.is_completed = true
            } catch (err) {
                this.error = err.message
            }
        }
    }
}
</script>