<template>
    <router-link
    :to="to"
    class="btn-learn-more">
        <span class="text-special mr-1">{</span>

        <span class="btn-learn-more--label">
            <slot>Learn more</slot>
        </span>

        <span class="btn-learn-more--arrow">
            <span>&#x203A;</span>
        </span>

        <span class="text-special ml-1">}</span>
    </router-link>
</template>

<script>
export default {
    name: 'btn_learn_more',
    props: {
        to: {
            type: String,
            required: true
        }
    }
}
</script>

<style lang="scss" scoped>
.btn-learn-more {
    display: inline-flex;
    flex-flow: row nowrap;
    align-items: center;

    min-width: var(--min-height-ui);
    min-height: var(--min-height-ui);

    text-decoration: none;
    line-height: 100%;

    font-size: 0.85rem;

    // border: 1px solid red;

    &--label {
        display: inline-block;
        position: relative;
        letter-spacing: 0.025em;
        text-transform: uppercase;
        margin-right: var(--size-ui);
        font-family: var(--typo-family-special);

        // &::after {
        //     content: "";
        //     position: absolute;
        //     bottom: 0;
        //     left: 0;
        //     width: 100%;
        //     height: var(--thickness);
        //     background-color: var(--color-typo);
        // }
    }

    &--arrow {
        font-family: var(--typo-family-special);
        font-size: 1.5em;
        // overflow: hidden;
        margin-top: -0.1em;
    }
}
</style>