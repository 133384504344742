import { createApp, reactive } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import components from './components'
import { fittext, specialletter } from './directives'
import { createHead, VueHeadMixin } from '@unhead/vue'
import { useToast } from 'vue-toastification'
import Api from './assets/libs/Api'

const api = new Api({
    app_id: 'smnfsc',
    prefix: 'smnfsc'
})

const app = createApp(App)
const head = createHead()
components(app)
app.mixin(VueHeadMixin)
app.directive('fittext', fittext)
app.directive('specialletter', specialletter)

app.config.globalProperties.$api = reactive(api)
app.config.globalProperties.$toast = useToast()
window.$api = app.config.globalProperties.$api

app.config.errorHandler = (err, vm, info) => {
    console.log('error handler', err, vm, info)
    // const payload = Object.values(err.payload || {})
    let message = err.message || err.code

    if (err.code === 'resource.validation' && process.env.NODE_ENV === 'development') {
        message += Object.values(err.fields)
    }

    // vm.$toast.error(String(message))
    return message
}

app.use(head).use(store).use(router)

router.isReady().then(() => {
    app.mount('#app', true)
})