import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'

import UnderConstruction from '../views/UnderConstruction.vue'
import Error404 from '../views/Err404.vue'
import Index from '../views/Index.vue'
import About from '../views/About.vue'
import Projects from '../views/Projects.vue'
import Project from '../views/Project.vue'
import Contacts from '../views/Contacts.vue'
import Signin from '../views/Signin.vue'
import ServiceMonkee from '../views/Monkee.vue'
import ServiceMonkeeOrder from '../views/MonkeeOrder.vue'

const routes = [
  {
    path: '/:pathMatch(.*)*',
    redirect: '/errors/404'
  },
  {
    path: '/errors/maintenance',
    name: 'error_maintenance',
    component: UnderConstruction
  },
  {
    path: '/errors/404',
    name: 'error_404',
    component: Error404
  },
  {
    path: '/',
    name: 'index',
    component: Index
  },
  {
    path: '/signin',
    name: 'signin',
    component: Signin
  },
  //
  {
    path: '/servizi/monkeecommerce',
    name: 'services_monkee',
    component: ServiceMonkee
  },
  {
    path: '/servizi/monkeecommerce/ordine',
    name: 'services_monkee_order',
    component: ServiceMonkeeOrder
  },
  //
  {
    path: '/about',
    name: 'about',
    component: About
  },
  {
    path: '/projects',
    name: 'projects',
    component: Projects
  },
  {
    path: '/projects/:slug',
    name: 'project',
    component: Project
  },
  {
    path: '/contacts',
    name: 'contacts',
    component: Contacts
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 }
  }
})

router.beforeEach((to, from, next) => {
  console.log('Router beforeEach() ext', to)

  const r = ['/errors/maintenance', '/servizi/monkeecommerce', '/servizi/monkeecommerce/ordine']
  store.commit('set_page_enter', to.name)
  store.commit('set_page_leave', from && from.name ? from.name : null)

  if (!r.includes(to.path)) {
    return next('/errors/maintenance')
  }

  return next()
})

export default router
