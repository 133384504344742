<template>
  <div class="index-view">
    <section
    class="pb-0"
    style="padding-top: 18px;"
    @click="show_ui = !show_ui">
      <!-- <p-figure
      ratio="1"
      overlay
      clipped
      src="https://images.pexels.com/photos/3435272/pexels-photo-3435272.jpeg?auto=compress&cs=tinysrgb&w=1600"
      style="position: absolute; top: 10vh; right: 0; width: 40%;" /> -->

      <sf-layout>
        <p-section-title>Hi . I'm Simone Fusco . Fullstack developer & Consulting</p-section-title>

        <div>
          <!-- creo, collaboro, sviluppo -->
          <h1 class="mb-0">Aiuto <span data-anim="text-scramble" class="text-outlined text-outlined-primary text-primary">{{ titles[title_index] }}</span></h1>
          <h1 class="mb-0">a realizzare i loro</h1>
          <h1 v-specialletter="'e'">progetti digitali sul web</h1>
        </div>

        <div>
          <p>Sono Simone Fusco, sviluppatore senior full-stack e consulente <br> con oltre 10 anni di esperienza nell'arte dello sviluppo web.</p>
          <p-btn-learnmore to="/about">Contact me</p-btn-learnmore>
        </div>

        <!-- <p>Offro quotidianamente soluzioni digitali di alta qualità, mettendo in campo una vasta gamma di competenze, problem solving e processi per soddisfare le esigenze di molteplici progetti.</p> -->

        <!-- <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
          <input type="hidden" name="cmd" value="_s-xclick" />
          <input type="hidden" name="hosted_button_id" value="FXRKVN4M4VRQ4" />
          <table>
            <tr>
              <td>
                <input type="hidden" name="on0" value="Taglia"/>
                <label for="on0">Taglia</label>
              </td>
            </tr>
            <tr>
              <td>
                <select name="os0">
                  <option value="Tessera - ">Tessera -  10,00 EUR</option>
                  <option value="Mezza tessera - ">Mezza tessera -  15,00 EUR</option>
                </select>
              </td>
            </tr>
          </table>
          <input type="hidden" name="currency_code" value="EUR" />
          <input type="image" src="https://www.paypalobjects.com/it_IT/i/btn/btn_paynow_LG.gif" border="0" name="submit" title="PayPal, il metodo semplice e sicuro per pagare online" alt="Acquista ora" />
        </form> -->
      </sf-layout>

      <!-- data-fittext="0.631" -->
      <!-- <h1
      v-fittext="0.631"
      class="text-special text-outlined">!_\\/[]{}*^#?</h1> -->
    </section>

    <div class="big-logo">
      <img src="/img/logo-3D.png" alt="">

      <!-- <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 94.66 120"
      style="enable-background:new 0 0 94.66 120;"
      xml:space="preserve">
          <path d="M38.64,42.75c0,4.35,1.77,8.29,4.62,11.14c2.85,2.85,6.79,4.62,11.14,4.62h22.39c0,0,0,25.49,0,25.51
          c0,0-6.59,0-6.83,0c-5.71,0-11.42,0-17.13,0.32c-4.48,0.26-8.66,1.4-12.42,3.92c-3.08,2.06-5.66,4.87-7.47,8.1
          c-2.27,4.06-3.37,8.22-3.37,12.86c0,0.55,0,9.28,0,9.28c0,0-28.4,0-28.4,0V86.61h23.65c8.7,0,15.76-7.06,15.76-15.76
          c0-4.35-1.77-8.29-4.62-11.14c-2.85-2.85-6.79-4.62-11.14-4.62H1.18V31.64h20.56c5.99,0,11.45-2.24,15.6-5.92
          c3.49-2.69,5.68-6.04,6.41-9.33c0.96-2.56,1.49-5.34,1.49-8.25V1.5h48.23v25.49H54.4C45.7,26.99,38.64,34.05,38.64,42.75z"/>
      </svg> -->
    </div>
  </div>
</template>

<script>
// import { gsap } from 'gsap'
// import { ScrambleTextPlugin } from 'gsap-trial/ScrambleTextPlugin'
// gsap.registerPlugin(ScrambleTextPlugin)

export default {
  name: 'index',
  data () {
    return {
      titles: ['agenzie', 'professionisti', 'aziende', 'startup'],
      title_index: 0,
      show_ui: false
    }
  },
  mounted () {
    this.$nextTick(this.start)
  },
  methods: {
    start () {
      // const masterTl = gsap.timeline({ repeat: -1 })

      // this.titles.forEach((word) => {
      //   const tl = gsap.timeline({
      //     repeat: 1,
      //     yoyo: true,
      //     repeatDelay: 1
      //     // ease: 'power2.inOut'
      //   })

      //   tl.to('[data-anim="text-scramble"]', {
      //     duration: 1,
      //     scrambleText: {
      //       text: word,
      //       // chars: '!<>-_\\/[]{}—=+*^?#',
      //       chars: 'upperAndLowerCase'
      //       // speed: 0.3,
      //       // revealDelay: 0.5,
      //       // tweenLength: false
      //     }
      //   })

      //   masterTl.add(tl)
      // })
    }
  }
}
</script>

<style lang="scss" scoped>
.big-logo {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: -1;

  > img {
    width: 100vw;
    max-width: 100vw;
  }

  > svg {
    width: 100vw;
    transform: scale(1.03);

    > path {
      fill: none;
      // fill: var(--color-primary);
      stroke: rgb(40, 40, 40);
      stroke-width: 0.1;
      stroke-dasharray: 0.1;
    }
  }
}
</style>