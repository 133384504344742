import Resource from './BaseResource'

class Me extends Resource {
    constructor(config, tools, version, path) {
        super(config, tools, version, path)

        this.exists = false
    }

    async get() {
        const res = await this.tools.http.get(`${this.api}`)

        if (res) for (const k in res) this[k] = res[k]

        return res
    }

    async getOrders(q) {
        const res = await this.tools.http.get(`${this.api}/orders`, q)
        return res
    }

    async getOrder(id, q) {
        const res = await this.tools.http.get(`${this.api}/orders/${id}`, q)
        return res
    }

    cancelOrder (id) {
        return this.tools.http.delete(`${this.api}/orders/${id}`)
    }

    addProductToWish (id) {
        return this.tools.http.post(`${this.api}/products/${id}/wishes`)
    }
}

export default Me
