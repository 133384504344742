// comps
import UI from './UI.vue'

import Layout from './layout/Layout.vue'
import Row from './layout/Row/Row.vue'
import Col from './layout/Col/Col.vue'

import Logo from './Logo.vue'
import Figure from './Figure.vue'
import Scrolldown from './Scrolldown.vue'
import BtnLearnMore from './BtnLearnMore.vue'
import SectionTitle from './SectionTitle.vue'
import Marquee from './Marquee.vue'

export default function createComp(app) {
    app.component('ui', UI)

    app.component('logo', Logo)
    app.component('p-figure', Figure)
    app.component('p-scrolldown', Scrolldown)
    app.component('p-section-title', SectionTitle)
    app.component('p-btn-learnmore', BtnLearnMore)
    app.component('p-marquee', Marquee)

    app.component('sf-layout', Layout)
    app.component('sf-row', Row)
    app.component('sf-col', Col)
}