<template>
    <div class="ui">
        <div>
            <div>
                <label for="color_background">color-background</label>
                <input
                v-model="styles.color_background"
                id="color_background"
                type="color"
                class="mr-ui">
                <span>{{ styles.color_background }}</span>
            </div>

            <div>
                <label for="color_typo">color-typo</label>
                <input
                v-model="styles.color_typo"
                id="color_typo"
                type="color"
                class="mr-ui">
                <span>{{ styles.color_typo }}</span>
            </div>

            <div>
                <label for="color_headline">color-headline</label>
                <input
                v-model="styles.color_headline"
                id="color_headline"
                type="color"
                class="mr-ui">
                <span>{{ styles.color_headline }}</span>
            </div>

            <div>
                <label for="color_paragraph">color-paragraph</label>
                <input
                v-model="styles.color_paragraph"
                id="color_paragraph"
                type="color"
                class="mr-ui">
                <span>{{ styles.color_paragraph }}</span>
            </div>

            <div>
                <label for="color_action">color-action</label>
                <input
                v-model="styles.color_action"
                id="color_action"
                type="color"
                class="mr-ui">
                <span>{{ styles.color_action }}</span>
            </div>

            <hr class="my-2">

            <div>
                <label for="size_ui">size-ui</label>
                <input
                v-model="styles.size_ui"
                id="size_ui"
                type="range"
                min="0"
                max="5"
                step="0.1"
                class="mr-ui">
                <span>{{ styles.size_ui }}rem</span>
            </div>

            <div>
                <label for="pa_layout">size-layout</label>
                <input
                v-model="styles.pa_layout"
                id="pa_layout"
                type="range"
                min="0"
                max="5"
                step="0.1"
                class="mr-ui">
                <span>{{ styles.pa_layout }}rem</span>
            </div>

            <div>
                <label for="boxed-ui">boxed-ui</label>
                <input
                v-model="styles.boxed_ui"
                id="boxed-ui"
                type="range"
                min="0"
                max="100"
                step="0.1"
                class="mr-ui">
                <span>{{ styles.boxed_ui }}%</span>
            </div>

            <hr class="my-2">

            <div>
                <label for="font-size-ui">font-size-ui</label>
                <input
                v-model="styles.font_size_ui"
                id="font-size-ui"
                type="range"
                min="12"
                max="50"
                step="1"
                class="mr-ui">
                <span>{{ styles.font_size_ui }}px</span>
            </div>

            <div>
                <label for="typo-line-height-ui">typo-line-height-ui</label>
                <input
                v-model="styles.lh_ui"
                id="typo-line-height-ui"
                type="range"
                min="0.5"
                max="5"
                step="0.01"
                class="mr-ui">
                <span>{{ styles.lh_ui }}</span>
            </div>

            <div>
                <label for="typo-ui-margin-bottom">typo-ui-margin-bottom</label>
                <input
                v-model="styles.mb_ui"
                id="typo-ui-margin-bottom"
                type="range"
                min="0"
                max="10"
                step="0.01"
                class="mr-ui">
                <span>{{ styles.mb_ui }}rem</span>
            </div>

            <hr>

            <div>
                <label for="typo-family-display">typo-family-display</label>
                <select
                v-model="styles.heading"
                id="typo-family-display"
                class="mr-ui">
                    <option value="'rm-neue-regular', sans-serif">Regular</option>
                    <option value="'rm-neue-semibold', sans-serif">Semibold</option>
                    <option value="'rm-neue-bold', sans-serif">Bold</option>
                </select>
            </div>

            <div>
                <label for="typo-line-height-display">typo-line-height-display</label>
                <input
                v-model="styles.lh_display"
                id="typo-line-height-display"
                type="range"
                min="0.5"
                max="5"
                step="0.01"
                class="mr-ui">
                <span>{{ styles.lh_display }}</span>
            </div>

            <div>
                <label for="typo_display_letter_spacing">typo-display-letter-spacing</label>
                <input
                v-model="styles.typo_display_letter_spacing"
                id="typo_display_letter_spacing"
                type="range"
                min="-1"
                max="5"
                step="0.001"
                class="mr-ui">
                <span>{{ styles.typo_display_letter_spacing }}em</span>
            </div>

            <div>
                <label for="typo-display-margin-botttom">typo-display-margin-botttom</label>
                <input
                v-model="styles.mb_heading"
                id="typo-display-margin-botttom"
                type="range"
                min="0"
                max="10"
                step="0.01"
                class="mr-ui">
                <span>{{ styles.mb_heading }}rem</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ui',
    emits: ['close'],
    data () {
        return {
            styles: {
                color_background: null,
                color_typo: null,
                color_headline: null,
                color_paragraph: null,
                color_action: null,

                size_ui: null,
                pa_layout: null,
                boxed_ui: null,

                lh_ui: null,
                lh_display: null,
                heading: "'rm-neue-semibold', sans-serif",
                typo_display_letter_spacing: null,
                mb_heading: null,
                mb_ui: null
            }
        }
    },
    watch: {
        styles: {
            deep: true,
            handler (newVal) {
                document.documentElement.style.setProperty('--color-background', newVal.color_background)
                document.documentElement.style.setProperty('--color-typo', newVal.color_typo)
                document.documentElement.style.setProperty('--color-headline', newVal.color_headline)
                document.documentElement.style.setProperty('--color-paragraph', newVal.color_paragraph)
                document.documentElement.style.setProperty('--color-action', newVal.color_action)

                document.documentElement.style.setProperty('--size-ui', newVal.size_ui + 'rem')
                document.documentElement.style.setProperty('--size-layout', newVal.pa_layout + 'rem')
                document.documentElement.style.setProperty('--boxed-ui', newVal.boxed_ui + '%')

                document.documentElement.style.setProperty('--typo-size-ui', newVal.font_size_ui + 'px')
                document.documentElement.style.setProperty('--typo-line-height-ui', newVal.lh_ui)
                document.documentElement.style.setProperty('--typo-ui-margin-bottom', newVal.mb_ui + 'rem')

                document.documentElement.style.setProperty('--typo-family-display', newVal.heading)
                document.documentElement.style.setProperty('--typo-display-letter-spacing', newVal.typo_display_letter_spacing + 'em')
                document.documentElement.style.setProperty('--typo-line-height-display', newVal.lh_display)
                document.documentElement.style.setProperty('--typo-display-margin-botttom', newVal.mb_heading + 'rem')
            }
        }
    },
    mounted () {
        this.$nextTick(() => {
            this.styles = {
                color_background: getComputedStyle(document.documentElement).getPropertyValue('--color-background'),
                color_typo: getComputedStyle(document.documentElement).getPropertyValue('--color-typo'),
                color_headline: getComputedStyle(document.documentElement).getPropertyValue('--color-headline'),
                color_paragraph: getComputedStyle(document.documentElement).getPropertyValue('--color-paragraph'),
                color_action: getComputedStyle(document.documentElement).getPropertyValue('--color-action'),

                size_ui: getComputedStyle(document.documentElement).getPropertyValue('--size-ui').replace('rem', ''),
                pa_layout: getComputedStyle(document.documentElement).getPropertyValue('--size-layout').replace('rem', ''),
                boxed_ui: getComputedStyle(document.documentElement).getPropertyValue('--boxed-ui').replace('%', ''),

                font_size_ui: getComputedStyle(document.documentElement).getPropertyValue('--typo-size-ui').replace('px', ''),
                heading: getComputedStyle(document.documentElement).getPropertyValue('--typo-family-display'),
                typo_display_letter_spacing: getComputedStyle(document.documentElement).getPropertyValue('--typo-display-letter-spacing').replace('em', ''),
                lh_ui: getComputedStyle(document.documentElement).getPropertyValue('--typo-line-height-ui'),
                lh_display: getComputedStyle(document.documentElement).getPropertyValue('--typo-line-height-display'),
                mb_heading: getComputedStyle(document.documentElement).getPropertyValue('--typo-display-margin-botttom').replace('rem', ''),
                mb_ui: getComputedStyle(document.documentElement).getPropertyValue('--typo-ui-margin-bottom').replace('rem', '')
            }
        })
    }
}
</script>

<style lang="scss" scoped>
.ui {
    position: fixed;
    bottom: 0;
    right: 0;
    font-size: 12px;
    z-index: 9999;
    border: 1px solid var(--color-typo);
    background-color: var(--color-background);

    hr {
        margin: 0.5rem 0 !important;
        border-color: grey;
    }

    > div {
        position: relative;
        width: 15vw;
        padding: 1rem;

        > div {
            margin-bottom: 0.5rem;

            > label {
                display: block;
                margin-bottom: 0.1rem;
            }
        }
    }
}
</style>