export const fittext = {
    mounted (el, binding, vnode, prevVnode) {
        console.log('fittext mounted()', el, binding)
        // console.log(binding)
        // console.log(vnode)
        // console.log(prevVnode)

        const compressor = binding.value || 1
        const settings = {
            min_font_size: Number.NEGATIVE_INFINITY,
            max_font_size: Number.POSITIVE_INFINITY
        }

        el.textfit = function () {
            // const rect = el.getBoundingClientRect()
            const comp = el.dataset.fittext || compressor
            const width = el.clientWidth
            console.log(width)

            const size = Math.max(
                Math.min(width / (comp * 10),
                parseFloat(settings.max_font_size)
            ), parseFloat(settings.min_font_size))

            el.style.fontSize = `${size}px`
        }

        el.textfit()
        window.addEventListener('resize', el.textfit)
    },
    unmounted (el) {
        console.log('fittext unmounted()', el)
        window.removeEventListener('resize', el.textfit)
    }
}

export const specialletter = {
    mounted (el, binding, vnode, prevVnode) {
        const values = binding.value.split(',').map(v => v.trim())
        const text = el.textContent
        const chars = []
        // const words = text.split(' ')

        for (let i = 0; i < text.length; i++) {
            let char = text[i]

            if (values.includes(char.toLowerCase())) {
                char = `<span class="text-special">${char}</span>`
            }

            chars.push(char)
        }

        el.innerHTML = chars.join('')
    }
}

export default {
    fittext
}