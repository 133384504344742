import { createStore } from 'vuex'
import { getCSSVar } from '../assets/libs/utils'
import {
    mobile as _is_mobile, // is iPhone, iPod, Android Phone, Windows Phone, Blackberry.
    tablet as _is_tablet,
    touchDevice as is_touch_device
} from 'is_js'

const static_break_value = parseInt(getCSSVar('--static-break-value').replace('px', ''))
const is_mobile = () => window.innerWidth < static_break_value || _is_mobile()
const is_tablet = () => (window.innerWidth >= 768 && window.innerWidth < 992) || _is_tablet()

export default createStore({
  state: {
    is_preloaded: false,
    is_loading: false,
    page_leave: null,
    page_enter: null,
    is_mobile: is_mobile(),
    is_tablet: is_tablet(),
    is_touch_device: is_touch_device()
  },
  getters: {
  },
  mutations: {
    set_page_enter(state, str) {
      state.page_enter = str
    },
    set_page_leave(state, str) {
      state.page_leave = str
    },
    set_is_preloaded(state, bool) {
      state.is_preloaded = bool
    },
    set_is_loading(state, bool) {
      state.is_loading = bool
    },
    resize(state) {
      state.is_mobile = is_mobile()
      state.is_tablet = is_tablet()
      state.is_touch_device = is_touch_device()
    }
  },
  actions: {},
  modules: {}
})
