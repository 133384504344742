<template>
    <div class="services-monkee--view">
        <section style="padding-top: 0; padding-bottom: 0;">
            <sf-layout>
                <p-figure
                v-for="n in 7"
                :key="n"
                :src="`/img/monkee/ig-story-${n}.jpeg`"
                style="max-width: 560px; margin: 0 auto;" />

                <div>
                    <video
                    controls="controls"
                    preload="metadata"
                    autoplay
                    style="width: 100%; max-width: 80%; height: auto; border: none; outline: none; margin: 0 auto;"
                    title="Monkeecommerce video"
                    aria-label="Monkeecommerce video">
                        <source src="/img/monkee.mp4" type="video/mp4" />
                        <!-- <source src="img/monkee.mp4" type="video/ogg" /> -->
                    </video>
                </div>
            </sf-layout>
        </section>
    </div>
</template>

<script>
export default {
    name: 'services_monkee'
}
</script>

<style lang="scss" scoped>
.services-monkee--view {
    background-color: white;
}
</style>