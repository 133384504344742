<template>
    <div class="under-construction">
        <section>
            <sf-layout>
                <div>
                    <logo class="mb-ui" />
                    <p-section-title text="Simone Fusco . Fullstack developer & Consulting" />
                </div>

                <div>
                    <h2>Und<span class="text-special">e</span>r co<span class="text-special">n</span>struc<span class="text-special">t</span>ion</h2>
                </div>

                <div>
                    <div class="mb-3">
                        <a
                        href="mailto:contact@simonefusco.com"
                        class="text-ui text-uppercase">contact@simonefusco.com</a>
                    </div>

                    <div>
                        <a
                        href="tel:+393296159000"
                        class="text-ui text-uppercase">329 615 90 00</a>
                    </div>
                </div>
            </sf-layout>
        </section>
    </div>
</template>

<script>
export default {
    name: 'error_maintenance'
}
</script>