<template>
    <router-link to="/">
        <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 94.66 120"
        style="enable-background:new 0 0 94.66 120;"
        xml:space="preserve"
        :style="{
            width: size + 'px',
            height: size + 'px'
        }">
            <path d="M38.64,42.75c0,4.35,1.77,8.29,4.62,11.14c2.85,2.85,6.79,4.62,11.14,4.62h22.39c0,0,0,25.49,0,25.51
            c0,0-6.59,0-6.83,0c-5.71,0-11.42,0-17.13,0.32c-4.48,0.26-8.66,1.4-12.42,3.92c-3.08,2.06-5.66,4.87-7.47,8.1
            c-2.27,4.06-3.37,8.22-3.37,12.86c0,0.55,0,9.28,0,9.28c0,0-28.4,0-28.4,0V86.61h23.65c8.7,0,15.76-7.06,15.76-15.76
            c0-4.35-1.77-8.29-4.62-11.14c-2.85-2.85-6.79-4.62-11.14-4.62H1.18V31.64h20.56c5.99,0,11.45-2.24,15.6-5.92
            c3.49-2.69,5.68-6.04,6.41-9.33c0.96-2.56,1.49-5.34,1.49-8.25V1.5h48.23v25.49H54.4C45.7,26.99,38.64,34.05,38.64,42.75z"/>
        </svg>
    </router-link>
</template>

<script>
export default {
    name: 'logo',
    props: {
        size: {
            type: [String, Number],
            required: false,
            default: 40
        }
    },
    data () {
        return {
            on: false
        }
    }
}
</script>

<style lang="scss" scoped>
a {
    display: inline-flex;
    flex-flow: row nowrap;
    align-items: center;
    text-decoration: none;
    pointer-events: auto;

    &:hover {
        > svg {
            > path {
                fill: var(--color-primary);
            }
        }
    }

    > svg {
        width: 100%;
        height: 100%;

        > path {
            fill: var(--color-typo);
        }
    }

    > span {
        display: none;
        text-transform: uppercase;
        font-weight: 600;
        margin-left: 1rem;
        line-height: 100%;
        margin-top: 12px;
    }
}
</style>