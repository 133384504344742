<template>
  <!-- <p-navframe
  ref="navframe"
  class="app--navframe" /> -->

  <!-- :style="{ paddingTop: navframe_top_h + 'px' }" -->
  <div
  ref="wrapper"
  data-scroll-container
  class="app--wrapper">
    <main
    data-scroll-section
    class="app--main">
      <router-view
      ref="view"
      v-slot="{ Component }">
        <transition
        @enter="enter"
        @leave="leave"
        mode="out-in"
        appear>
          <!-- v-if="is_preloaded" -->
          <component
          ref="page"
          :key="$route.name"
          :is="Component" />
        </transition>
      </router-view>
    </main>

    <!-- <p-footer
    ref="footer"
    data-scroll-section
    class="app--footer" /> -->
  </div>

  <!-- <p-cursor /> -->
</template>

<script>
import { mapState } from 'vuex'
// import { gsap } from 'gsap'
import Font from 'fontfaceobserver'
import { fixVh } from '@/assets/libs/utils'
import LocoScroll from 'locomotive-scroll'

// import Cursor from '@/components/Cursor.vue'
// import Navframe from '@/components/layout/Navbar.vue'
// import Footer from '@/components/layout/Footer.vue'

export default {
  name: 'App',
  components: {
    // 'p-navframe': Navframe
    // 'p-footer': Footer,
    // 'p-cursor': Cursor
  },
  data () {
    return {
      scroll: null,
      navframe_top_h: 0
    }
  },
  computed: {
    ...mapState({
        resources: state => state.resources,
        preloaded: state => state.preloaded,
        loading: state => state.loading,
        in_transition: state => state.in_transition
    })
  },
  head () {
    return {
      title: 'Simone Fusco | Fullstack developer',
      meta: [
        { name: 'description', content: '' },
        { name: 'article:modified_time', content: '' },
        // OG
        { name: 'og:type', content: '' },
        { name: 'og:site_name', content: '' },
        { name: 'og:title', content: '' },
        { name: 'og:description', content: '' },
        { name: 'og:image', content: '' },
        { name: 'og:url', content: '' },
        // TWC
        { name: 'twitter:card', content: '' },
        { name: 'twitter:title', content: '' },
        { name: 'twitter:description', content: '' },
        { name: 'twitter:image', content: '' },
        // indexing
        { name: 'robots', content: '' }
      ],
      script: [
        { type: 'application/ld+json', content: '' }
      ]
    }
  },
  created () {
    this.$router.beforeEach((to, from, next) => {
      console.log('App router.beforeEach() int')
      next()
    })
  },
  mounted () {
    this.$nextTick(this.start)
  },
  watch: {
    in_transition (newVal) {
      console.log('App watch in_transition', newVal)

      if (newVal === true) this.pause()
      else this.play()
    },
    'menu.on' (newVal) {
      console.log('App watch menu.on', newVal)

      if (newVal === true) this.pause()
      else this.play()
    }
  },
  methods: {
    async start () {
      console.log('App start()')
      document.body.classList.toggle('theme-dark')

      const font = new Font('gridular-regular')
      await font.load().then(() => {
        console.log('Output Sans has loaded.')
      })

      this.resize()
      window.addEventListener('resize', this.resize)

      this.$store.commit('set_is_loading', true)

      this.$store.commit('set_is_preloaded', true)
      this.$store.commit('set_is_loading', false)

      this.$store.commit('set_is_preloaded', true)

      const r = ['/errors/maintenance', '/servizi/monkeecommerce', '/servizi/monkeecommerce/ordine']
      if (!r.includes(this.$route.path)) {
        this.$router.push('/errors/maintenance')
      }

      // this.$nextTick(this.play)
    },
    play () {
      console.log('App play()')

      // Note: scroll-sections are optional but recommended to improve performance, particularly in long pages.
      const sections = [...this.$refs.page.$el.querySelectorAll('section')]
      console.log(this.$refs)

      if (sections.length > 0) {
        sections.forEach((el) => {
          if (!el.hasAttribute('data-scroll-section')) {
            el.setAttribute('data-scroll-section', '')
          }
        })
      }

      if (!this.scroll) {
        this.scroll = new LocoScroll({
          el: document.querySelector('[data-scroll-container]'),
          smooth: true,
          initPosition: { x: 0, y: 0 },
          direction: 'vertical',
          gestureDirection: 'vertical',
          lerp: 0.1,
          getDirection: true,
          getSpeed: false,
          multiplier: 2,
          firefoxMultiplier: 4,
          touchMultiplier: 2,
          scrollFromAnywhere: false,
          tablet: { direction: 'vertical', smooth: true },
          smartphone: { direction: 'vertical', smooth: true }
        })
      }

      this.resize()
      this.scroll.update() // Updates all element positions
      this.scroll.start() // Restarts the scroll events

      this.scroll.on('scroll', this.scrollHandler)

      const blanks = [...document.querySelectorAll(['a[target="_blank"]'])]
      blanks.forEach((el) => {
        if (!el.hasAttribute('rel')) {
          el.setAttribute('rel', 'noopener')
        }
      })
    },
    pause () {
      console.log('App pause()')

      if (this.scroll) {
        this.scroll.stop() // Stops the scroll events
      }
    },
    scrollHandler (evt) {
      // console.log('scrollHandler', evt)
    },
    resize () {
      fixVh()
      this.$store.commit('resize')
      // this.navframe_top_h = this.$refs.navframe ? this.$refs.navframe.$refs.navbar_top.$el.clientHeight : 0
    },
    enter (el, next) {
      next()
      // gsap.fromTo(el, {
      //   x: 40,
      //   autoAlpha: 0
      // }, {
      //   x: 0,
      //   autoAlpha: 1,
      //   duration: 1,
      //   ease: 'expo.out',
      //   clearProps: 'all',
      //   onComplete: next
      // })
    },
    leave (el, next) {
      next()
      // gsap.fromTo(el, {
      //   x: 0,
      //   autoAlpha: 1
      // }, {
      //   x: 40,
      //   autoAlpha: 0,
      //   duration: 1,
      //   ease: 'expo.in',
      //   onComplete: next
      // })
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/styles/index.scss';
</style>