<template>
    <p class="small">
        <span
        v-if="number"
        class="mr-ui">( {{ number }} )</span>

        <span class="text-uppercase">
            <slot />
        </span>
    </p>
</template>

<script>
export default {
    name: 'section_title',
    props: {
        number: {
            type: String,
            required: false,
            default: null
        },
        text: {
            type: String,
            required: false,
            default: null
        }
    }
}
</script>

<style lang="scss" scoped>
p {
    margin-bottom: var(--typo-display-margin-botttom);
}
</style>