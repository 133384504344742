<template>
    <div class="marquee">
        <div
        v-for="(item, i) in split.words"
        :key="i"
        ref="line"
        class="marquee--line">
            <span
            v-for="(word, i) in item"
            :key="i"
            ref="word"
            class="marquee--line--word">{{ word }}</span>
        </div>
    </div>
</template>

<script>
import gsap from 'gsap'
import Loop from '@/assets/libs/loop'

const loop = new Loop()

export default {
    name: 'marquee',
    props: {
        data: {
            type: String,
            required: false,
            default: 'text text, text, text text'
        },
        'scroll-amount-y': {
            type: Number,
            required: false,
            default: 0
        },
        'scroll-velocity-y': {
            type: Number,
            required: false,
            default: 0
        },
        separator: {
            type: String,
            required: false,
            default: '-'
        }
    },
    computed: {
        linesCount () {
            return this.split.lines.length
        },
        wordsCount () {
            return this.split.words.length
        }
    },
    data () {
        return {
            split: {
                lines: [],
                words: []
            },
            skew: 0,
            mult: 0.5
        }
    },
    created () {
        this.split.lines = this.data.split(this.separator)
        this.split.words = this.split.lines.map(line => line.split(' '))
    },
    mounted () {
        this.$nextTick(this.start)
    },
    methods: {
        start () {
            loop.add(this.update, 'marquee-frame')
        },
        stop () {
            loop.remove('marquee-frame')
        },
        update () {
            this.skew = this.scrollVelocityY * 2

            if (this.linesCount >= 4) {
                gsap.set(this.$refs.line[0], { x: -this.scrollAmountY * this.mult, skewX: this.skew })
                gsap.set(this.$refs.line[1], { x: this.scrollAmountY * this.mult, skewX: -this.skew })
                gsap.set(this.$refs.line[2], { x: -this.scrollAmountY * this.mult, skewX: this.skew })
                gsap.set(this.$refs.line[3], { x: this.scrollAmountY * this.mult, skewX: this.skew })
            } else {
                console.log('lines over 4')
            }
        }
    },
    beforeUnmount () {
        this.stop()
    }
}
</script>

<style lang="scss" scoped>
.marquee {
    position: relative;
    white-space: nowrap;

    font-size: 80px;

    letter-spacing: 15px;
    text-transform: uppercase;
    text-align: center;

    pointer-events: none;
    mix-blend-mode: difference;

    // z-index: 1;

    &--line {
        display: block;
        position: relative;
        white-space: nowrap;
        margin-bottom: var(--size-ui);

        &:last-child { margin-bottom: 0; }

        &--word {
            display: inline-block;
            margin-right: var(--size-ui);

            &:last-child { margin-right: 0; }
        }
    }
}
</style>