<template>
    <button class="scrolldown text-special">
        <span>&#x2193;</span>
    </button>
</template>

<script>
export default {
    name: 'scrolldown'
}
</script>

<style lang="scss">
.scrolldown {
    font-size: 3em;
}
</style>