<template>
    <div
    :class="classes"
    :style="{ maxWidth: maxWidth ? maxWidth + 'px' : null }">
        <slot />
    </div>
</template>

<script>
export default {
    name: 'sf_layout',
    props: {
        fluid: {
            type: Boolean,
            required: false,
            default: false
        },
        'max-width': {
            type: [String, Number],
            required: false,
            default: null
        }
    },
    computed: {
        classes () {
            return ['sf-layout', {
                'sf-layout-fluid': this.fluid
            }]
        }
    }
}
</script>

<style lang="scss">
.sf-layout {
    position: relative;
    width: 100%;
    max-width: var(--boxed-ui);

    margin-left: auto;
    margin-right: auto;

    // padding-left: var(--size-layout);
    // padding-right: var(--size-layout);
    padding: var(--size-layout);

    &.sf-layout-fluid {
        max-width: 100%;
    }
}
</style>
